







































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import businessStoreModule from "@/store/modules/business";
import { setAuth } from "@/util/auth";

const { mapActions: businessActions } =
  createNamespacedHelpers("BUSINESS_TYPE");

export default Vue.extend<any, any, any, any>({
  name: "BusinessType",
  beforeRouteEnter: (from, to, next) => {
    next((vm) => {
      const { t: token } = vm.$route.query;

      setAuth({ token: `${token}` }, false);
      next();
    });
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    valid: false,
    businessTypes: [
      { name: "Beauty", icon: "mdi-face-woman" },
      { name: "Travel", icon: "mdi-airplane" },
      { name: "Activities", icon: "mdi-tent" },
      { name: "Wellness", icon: "mdi-hand-heart" },
      { name: "Hair", icon: "mdi-hair-dryer" },
      { name: "Barber", icon: "mdi-content-cut" },
      { name: "Fitness", icon: "mdi-dumbbell" },
      //{ name: "General Services", icon: "mdi-hammer-wrench" },
      { name: "Medi-Aesthetics", icon: "mdi-pill" },
      { name: "Event Planning", icon: "mdi-calendar-multiple" },
      { name: "Coach Services", icon: "mdi-account-tie" },
      { name: "General Services", icon: "mdi-expand-all" },
    ],
    businessType: undefined as undefined | { name: string; icon: string },
    businessName: "",
    message: "",
    businessEmail: "",
  }),
  methods: {
    ...businessActions(["createBusiness"]),
    selectBusinessType() {
      let valid = (
        this.$refs.businessTypeForm as Element & { validate: () => boolean }
      )?.validate();

      if (!this.businessType) {
        this.message = "Please select a business type.";
        return;
      }

      this.message = "";

      if (valid) {
        this.createBusiness({
          name: this.businessName,
          userId: this.userId,
          type: this.businessType?.name.toLowerCase(),
          email: this.businessEmail,
        }).then((business) => {
          if (business) {
            this.$router.push({
              path: `/sign-up/plan/${business._id}`,
            });
          }
        });
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("BUSINESS_TYPE")) {
      this.$store.registerModule("BUSINESS_TYPE", businessStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("ROLE");
  },
});
